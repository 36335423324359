<template>
  <div id="state">
    <div class="conteneur">
      <div class="sidebar">
        <sidebar />
      </div>
      <div v-if="$store.state.position == 'gear'" class="sidebar-extend">
        <sidebarextend />
      </div>
      <div
        v-else-if="$store.state.position == 'history'"
        class="sidebar-extend"
      >
        <sidebarextendhistory />
      </div>
      <div v-bind:class="!queryHint ? 'my-auto' : ''" class="recherche">
        <img
          v-if="!queryHint"
          class="logo mx-auto d-block"
          src="../assets/logo.png"
          alt="Logo MyDataBall"
          height="92"
        />
        <barreRecherche />
        <optionVisualisation />
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import optionVisualisation from "@/components/optionVisualisation.vue";
import barreRecherche from "@/components/barreRecherche.vue";
import sidebar from "@/components/sidebar.vue";
import sidebarextend from "@/components/sidebar_extend.vue";
import sidebarextendhistory from "@/components/sidebar_extend_history.vue";

export default {
  name: "Recherche",
  components: {
    sidebar,
    sidebarextend,
    sidebarextendhistory,
    barreRecherche,
    optionVisualisation,
  },
  computed: {
    ...mapState(["queryHint"]),
  },
  mounted: function () {
    if (this.$route["name"] == "recherche-search") {
      this.$router.push({
        path: "results",
        query: Object.assign({}, this.$route.query),
      });
    }
  },
};
</script>

<style scoped>
#state {
  height: 100vh;
  width: 100vw;
}
.conteneur {
  display: flex;
  height: 100%;
}
.recherche {
  width: 97%;
}
.sidebar-extend {
  background-color: #7a9dce;
  width: 250px;
  margin: 0 10px 0 0;
}
.logo {
  mix-blend-mode: exclusion;
  margin-bottom: 20px;
  margin-top: -92px;
}
</style>
