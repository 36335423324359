<template>
  <div class="centre text-center my-3">
    <ul class="nav nav-tabs nav justify-content-center">
      <li v-for="tab in tabs" v-bind:key="tab.name" class="nav-item">
        <a
          v-bind:class="['nav-link', { active: currentTab === tab.name }]"
          v-on:click="changeView(tab.name)"
          aria-current="page"
        >
          <i class="bi" v-bind:class="tab.class"></i>
          {{ tab.name }}
        </a>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.tab-button {
  padding: 6px 10px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  cursor: pointer;
  background: #f0f0f0;
  margin-bottom: -1px;
  margin-right: -1px;
}
.tab-button:hover {
  background: #09172aca;
}
.tab-button.active {
  background: #09172a;
}
.tab {
  /* border: 1px solid #ccc; */
  height: 86vh;
  padding-left: 5px;
  padding-right: 5px;
}
.btn {
  background-color: #22609e;
  color: white;
}
.centre {
  margin: auto;
}
</style>

<script>
export default {
  name: "optionVisualisation",
  components: {},
  data: function () {
    return {
      currentTab: "results",
      tabs: [
        { name: "results", class: "bi-search" },
        { name: "list", class: "bi-list" },
        { name: "graph", class: "bi-bezier2" },
        { name: "tree", class: "bi-pentagon-fill" },
      ],
      tab: "",
    };
  },
  computed: {
    currentTabComponent: function () {
      return this.currentTab.toLowerCase();
    },
  },
  methods: {
    changeView: function (tab) {
      if (tab != this.currentTab) {
        this.$router.replace({
          path: tab,
          query: Object.assign({}, this.$route.query),
        });
        this.currentTab = tab;
      }
    },
  },
  mounted: function () {
    let name = this.$route["name"].match(/^recherche-([a-zA-Z]+)/)[1];
    if (name == "search") {
      this.currentTab = "results";
      return;
    }
    this.currentTab = name;
  },
};
</script>
